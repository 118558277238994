"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorReporter = void 0;
var getSentry_1 = require("./getSentry");
function createSentry() {
    return {
        captureException: function (exception, captureContext) {
            //@ts-expect-error
            if (process.env.NODE_ENV !== "test") {
                console.error(exception);
            }
            return getSentry_1.Sentry.captureException(exception, captureContext);
        },
        captureMessage: function (message, level) {
            return getSentry_1.Sentry.captureMessage(message, level);
        },
        setTags: function (tags) {
            getSentry_1.Sentry.setTags(tags);
        },
        setExtras: function (extras) {
            getSentry_1.Sentry.setExtras(extras);
        },
        addBreadcrumb: function (breadcrumb) {
            getSentry_1.Sentry.addBreadcrumb(breadcrumb);
        },
        breadcrumb: function (message, data) {
            if (data === void 0) { data = {}; }
            getSentry_1.Sentry.addBreadcrumb({ message: message, data: data });
        },
        _addEventProcessor: function (callback) {
            getSentry_1.Sentry.configureScope(function (scope) {
                scope.addEventProcessor(callback);
            });
        },
    };
}
exports.ErrorReporter = createSentry();
