"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Sentry = void 0;
var FetchTransport = /** @class */ (function () {
    function FetchTransport() {
    }
    return FetchTransport;
}());
var createMockSentryLogger = function () {
    //@ts-expect-error
    if (process.env.NODE_ENV !== "test") {
        console.warn("mocked sentry used!");
    }
    return {
        captureException: function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            console.error.apply(console, args);
            return 'mocked sentry exception';
        },
        captureMessage: function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            console.log.apply(console, args);
            return 'mocked sentry message';
        },
        setTags: function () { },
        setExtras: function () { },
        addBreadcrumb: function () { },
        breadcrumb: function () { },
        _addEventProcessor: function () { },
        configureScope: function () { },
        init: function () { },
        Transports: {
            FetchTransport: FetchTransport
        }
    };
};
// declare var Sentry: any;
var anyWindow = window;
function hasSentry() {
    return typeof anyWindow.Sentry !== "undefined" && Boolean(anyWindow.Sentry.captureException);
}
exports.Sentry = hasSentry() ? anyWindow.Sentry : createMockSentryLogger();
